import React, { useState } from 'react'
import './Header.css'

const Header = () => {
  //Change background header
  window.addEventListener('scroll', () => {
    const header = document.querySelector('.header');
    //when the scroll is higher than 560 vierport height.
    header.classList.toggle('scroll-header', window.scrollY >= 80);
  });

  //Toggle menu
  const [Toggle, showMenu] = useState(false);
  const [activeNav, setActiveNav] = useState('#home')

  return (
    <header className='header'>
      <nav className='nav container'>
        <a href='index.html' className='nav__logo'>Nicolas</a>

        <div className={Toggle ? 'nav__menu show-menu' : 'nav__menu'}>
          <ul className='nav__list grid'>
            <li className='nav__item'>
              <a href='#home' 
              onClick={() => setActiveNav('#home')} 
              className={activeNav === '#home' ? 'nav__link active-link' : 'nav__link'}>
                <i className='uil uil-estate nav__icon'></i> Home
              </a>
            </li>

            <li className='nav__item'>
              <a href='#about'
              onClick={() => setActiveNav('#about')} 
              className={activeNav === '#about' ? 'nav__link active-link' : 'nav__link'}>
                <i className='uil uil-user nav__icon'></i> About
              </a>
            </li>

            <li className='nav__item'>
              <a href='#skills' 
              onClick={() => setActiveNav('#skills')} 
              className={activeNav === '#skills' ? 'nav__link active-link' : 'nav__link'}>
                <i className='uil uil-file-alt nav__icon'></i> Skills
              </a>
            </li>

            <li className='nav__item'>
              <a href='#work'
              onClick={() => setActiveNav('#work')} 
              className={activeNav === '#work' ? 'nav__link active-link' : 'nav__link'}>
                <i className='uil uil-briefcase-alt nav__icon'></i> Work
              </a>
            </li>

            <li className='nav__item'>
              <a href='#contact' 
              onClick={() => setActiveNav('#contact')} 
              className={activeNav === '#contact' ? 'nav__link active-link' : 'nav__link'}>
                <i className='uil uil-message nav__icon'></i> Contact
              </a>
            </li>
          </ul> 

          <i className='uil uil-times nav__close' onClick={() => showMenu
            (!Toggle)}></i>
        </div>

        <div className='nav__toggle' onClick={() => showMenu
          (!Toggle)}>
          <i className='uil uil-apps'></i>
        </div>
      </nav>
    </header>
  );
};

export default Header